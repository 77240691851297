import React, { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useModalRootContext } from '@vkontakte/vkui'
import { Text } from 'modals/TransformPetToNFT/TransfromPetToNFTModal'
import { Button } from 'src/components/base'
import { API_URL, STATIC_IMAGES_PATH } from 'src/config/constants'
import { ModalsProps } from 'src/const/router'
import { usePopout } from 'src/hooks'
import { makeRequest } from 'utils/api'
import { openTelegramLink } from 'utils/openURL'
import { sendPayment } from 'utils/ton/connectWallet'
import { handleGetTonPayments } from 'utils/ton/GetPayments'

import Modal from 'components/modals/Modal'
import { RootState, useAppDispatch, useAppSelector } from 'store/index'
import { getPaymentProcess, setPaymentProcess } from 'store/payment'
import { clearPetState } from 'store/region'
import { decrementBalance, deletePet, updateUserRegion } from 'store/user'

import { LOCALES } from '@gatto/locales'
import { mintPrice, Platform } from '@gatto/shared'

import { useWalletCheckWithUpdate } from '../../hooks/useWalletCheck'
import { MINI_APP } from '../../index'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-self: stretch;
`

export const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`

type WarnTransformModalProps = ModalsProps['warnTransform']

const WarnTransformModal: FC<WarnTransformModalProps> = ({
  setBackground,
  petId,
  walletId,
  pet,
}) => {
  const { t } = useTranslation()
  const region = useAppSelector((state: RootState) => state.region).data
  const paymentProcess = useAppSelector(getPaymentProcess)
  const { user } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { updateModalHeight } = useModalRootContext()
  const location = useLocation()
  function handleDeletePet(id: string) {
    dispatch(deletePet(id))
    dispatch(updateUserRegion({ regionId: region?._id, egg: null, pet: null }))
    dispatch(clearPetState())
  }

  const mintingStarted = usePopout('mintingStarted', true, {})
  const errorPopout = usePopout('statusAlert', true, {
    type: 'error',
    header: `${t(LOCALES.buyError)}`,
  })
  const tonPaymentPopout = usePopout('statusAlert', true, {
    type: 'success',
    header: `${t(LOCALES.completed_payment)}`,
  })
  const successPopout = usePopout('statusAlert', true, {
    type: 'success',
    subheader: `${t(LOCALES.buySuccess)}`,
  })
  const waitPopout = usePopout('statusAlert', true, {
    type: 'custom',
    subheader: `${t(LOCALES.process_of_purchase)}`,
  })

  const paymentProcessPopout = usePopout('statusAlert', true, {
    type: 'error',
    subheader: `${t(LOCALES.error_paymentProcess)}`,
  })

  const walletExistPopout = usePopout('walletNotExist', false)
  const [data, updateData] = useWalletCheckWithUpdate(false)

  const isReqNeeded = useRef<boolean>(false)
  const intervalValue: { current: NodeJS.Timeout | null } = useRef(null)

  useEffect(() => {
    updateModalHeight()
  }, [updateModalHeight])

  if (!pet) return

  const price = mintPrice[pet.evolution] as number

  const isTelegramPayment =
    MINI_APP === Platform.TG && user && user.balance.hard < price

  async function getReq() {
    if (paymentProcess) return paymentProcessPopout()
    if (region?.pet?._id) {
      // const { isAllowed } = await makeRequest('nft.isMintAllowed', {})
      //
      // if (!isAllowed) {
      //   openErrorPopout()
      //   return
      // }

      if (!isTelegramPayment) {
        mintingStarted()
        handleDeletePet(region.pet._id.toString())

        if (!walletId) return

        const response = await makeRequest('nft.mint', {
          walletId,
          petId: region.pet._id.toString(),
        })

        if (response) {
          dispatch(decrementBalance({ hard: price }))
          const encodedData = encodeURIComponent(
            `${response.nft.contract}&${response.nft.imageUrl}&${response.nft.url}`,
          )
          openTelegramLink(
            `${STATIC_IMAGES_PATH}/uploads/minting/index.html?data=${encodedData}`,
          )
        }
      } else {
        isReqNeeded.current = true
        const uniqueId = await sendPayment(
          price,
          `${'minting'}:${walletId}:${petId}`,
          // tonPaymentPopout,
        )
        if (!uniqueId) return
        mintingStarted()
        // dispatch(setPaymentProcess(true))
        intervalValue.current = setInterval(async () => {
          const res = await handleGetTonPayments({
            uniqueId,
            intervalValue,
            isReqNeeded,
            successPopout,
            errorPopout,
            waitPopout,
            stopInterval: () => {
              dispatch(setPaymentProcess(false))
            },
            startInterval: () => {
              dispatch(setPaymentProcess(true))
            },
          })

          if (res?.status === 'got') {
            if (region?.pet?._id) {
              handleDeletePet(region.pet._id.toString())
              const response = await makeRequest('pet.getById', { id: petId })
              const encodedData = encodeURIComponent(
                `${response.nft.contractAddress}&${response.nft.imageUrl}&${response.nft.imageUrl}`,
              )
              openTelegramLink(
                `${STATIC_IMAGES_PATH}/uploads/minting/index.html?data=${encodedData}`,
              )
            }
          }
        }, 5000)
      }
    }
  }

  return (
    <Modal
      type="minting"
      header={t(LOCALES.convertPetToNTFConfirm)}
      headerPosition="center"
      setBackground={setBackground}
      background={false}
    >
      <Main>
        <Text>
          {/*<span>*/}
          {/*  При превращении игрового питомца в NFT-токен он исчезнет с биома и*/}
          {/*  попадёт на склад.*/}
          {/*</span>*/}
          <span>{t(LOCALES.convertPetToNTFInfo)}</span>
        </Text>
        <ButtonWrapper>
          <StyledButton variant="dangerous" onClick={() => navigate(-1)}>
            {t(LOCALES.no)}
          </StyledButton>
          <StyledButton
            onClick={async () => {
              const userWallets = data.length ? data : await updateData()
              if (!userWallets.length) {
                walletExistPopout()
                return
              }
              await getReq()
            }}
            variant="success"
          >
            {t(LOCALES.yes)}
          </StyledButton>
        </ButtonWrapper>
      </Main>
    </Modal>
  )
}

export default WarnTransformModal
