import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Popouts } from 'const/router'
import { TFunction } from 'i18next'
import { API_URL, STATIC_IMAGES_PATH } from 'src/config/constants'

import CurrencyIcon from 'assets/svg/coin.svg'
import { Header, Popout, StatusType, Subheader } from 'components/base'
import { leagues } from 'pages/LeaguesPage/LeaguesPage'
import { PrizeInfo } from 'pages/PrizeInfoPage/PrizeInfoPage'
import { getItemBackground } from 'pages/PrizeInfoPage/utils'

import { LOCALES } from '@gatto/locales'
import {
  EssenceType,
  GameLeague,
  GameLeaguePrizes,
  PetRarity,
} from '@gatto/shared'

const Img = styled.img`
  width: 125px;
  height: 125px;
  align-self: center;
`

const Title = styled.div`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings:
    'clig' off,
    'liga' off;

  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
`

const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Subtitle = styled.div`
  color: var(--gray-gray-100-secondary, #888);
  font-feature-settings:
    'clig' off,
    'liga' off;

  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const Infos = styled.div`
  display: flex;
  column-gap: 10px;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const More = styled.div`
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #3a6edf;
  cursor: pointer;
`

const BackImageBlock = styled.div<{ url: string }>`
  width: 155px;
  border-radius: 0.625rem;
  position: relative;
  padding: 10px;
  font-weight: bold;
  z-index: 1;

  &:before {
    content: ' ';

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    width: 155px;
    height: 100%;
    border-radius: inherit;

    background-image: url(${(props) => props.url});
    background-repeat: no-repeat;
    background-size: 170%;
    background-position: center;

    filter: opacity(0.1);
  }
`

const IconBackground = styled.div`
  width: 24px;
  height: 24px;
  padding: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(
    --gradient-warning-10,
    linear-gradient(
      180deg,
      rgba(255, 170, 69, 0.1) 0%,
      rgba(255, 122, 51, 0.1) 100%
    ),
    #fff
  );
`

const CoinIcon = styled.img`
  width: 14.817px;
  height: 16px;
`

const Count = styled.div`
  color: var(--black-black-100-primary, #000);
  leading-trim: both;
  text-edge: cap;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 5px;
`

const CurrencyType = styled.div`
  color: var(--gray-gray-100-secondary, #888);
  leading-trim: both;
  text-edge: cap;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Nunito;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const Orange = styled.div`
  width: 8px;
  height: 4px;
  border-radius: 32px;
  background: var(
    --gradient-warning,
    linear-gradient(180deg, #ffaa45 0%, #ff7a33 100%)
  );
  margin-top: 8px;
`

const IconImg = styled.img`
  width: 28px;
  height: 30px;
  object-fit: contain;
`

const IconBg = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  padding: 10px;
  border-radius: 10px;
  ${(props) => (props.color ? `background: ${props.color};` : '')}
`

const IconsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 4px;
`

const getUrlAndTitle = (
  t: TFunction,
  rarity: PetRarity | EssenceType,
): { photoUrl: string; name: string } => {
  switch (rarity) {
    case 'normal':
      return {
        photoUrl: `${STATIC_IMAGES_PATH}/uploads/eggs/egg_reg.png`,
        name: t(LOCALES.egg_normal),
      }
    case 'rare':
      return {
        photoUrl: `${STATIC_IMAGES_PATH}/uploads/eggs/egg_rare.png`,
        name: t(LOCALES.egg_rare),
      }
    case 'mif':
      return {
        photoUrl: `${STATIC_IMAGES_PATH}/uploads/eggs/egg_mif.png`,
        name: t(LOCALES.egg_mif),
      }
    case 'legendary':
      return {
        photoUrl: `${STATIC_IMAGES_PATH}/uploads/eggs/egg_leg.png`,
        name: t(LOCALES.egg_legendary),
      }
    case 'small':
      return {
        photoUrl: `${STATIC_IMAGES_PATH}/uploads/essences/exp_small.png`,
        name: t(LOCALES.essence_small),
      }
    default:
      return { photoUrl: '', name: '' }
  }
}

type LeagueInfoPopoutProps = Popouts['leagueInfo']

const LeagueInfoPopout: FC<LeagueInfoPopoutProps> = ({ league }) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<StatusType>()
  const navigate = useNavigate()

  const currentLeague = leagues.find(({ name }) => name === league)

  let prizes: Array<PrizeInfo | undefined> | undefined = []
  let maxCoins: number | undefined

  if (currentLeague) {
    prizes = GameLeaguePrizes[currentLeague.name]
      .at(0)
      ?.extraAwards.map((item) => {
        if (item.metadata && typeof item.metadata !== 'number') {
          if (
            currentLeague.name !== GameLeague.Platinum &&
            currentLeague.name !== GameLeague.Diamond &&
            item.metadata === PetRarity.Legendary
          ) {
            return
          }

          return {
            itemTypeRarity: item.metadata,
            ...getUrlAndTitle(t, item.metadata),
            percent: item.winPercent,
          }
        }
      })
      .filter((item) => !!item)

    maxCoins = GameLeaguePrizes[currentLeague.name].at(0)?.petMaxLvlMoneyAward
  }

  return (
    <Popout
      type="default"
      background={true}
      width="353px"
      isFullScreen={false}
      status={status}
      setStatus={setStatus}
    >
      <Img src={currentLeague?.src} />
      <Header>{t(currentLeague?.title)}</Header>
      <Subheader>{t(currentLeague?.subtitle)}</Subheader>
      <Title>{t(LOCALES.competitionRewards)}</Title>
      <Infos>
        <InfoWrapper>
          <Subtitle>{t(LOCALES.currency)}</Subtitle>
          <BackImageBlock url={CurrencyIcon}>
            <IconBackground>
              <CoinIcon src={CurrencyIcon} />
            </IconBackground>
            <Count>
              {t(LOCALES.countTo)} {maxCoins}
            </Count>
            <CurrencyType>{t(LOCALES.coinsOnce)}</CurrencyType>
            <Orange />
          </BackImageBlock>
        </InfoWrapper>
        <InfoWrapper>
          <SubtitleWrapper>
            <Subtitle>{t(LOCALES.items)}</Subtitle>
            <More
              onClick={() => {
                setStatus('disappear')

                setTimeout(
                  () =>
                    navigate('/prizeInfo', {
                      replace: true,
                      state: { title: t(LOCALES.rewards), body: prizes },
                    }),
                  50,
                )
              }}
            >
              {t(LOCALES.details)}
            </More>
          </SubtitleWrapper>
          <IconsWrapper>
            {prizes &&
              prizes.map((item, index) => {
                if (!item) return null

                const backgroundColor = getItemBackground(
                  item.itemTypeRarity,
                  item.itemType,
                )

                return (
                  <IconBg color={backgroundColor} key={index}>
                    <IconImg src={`${STATIC_IMAGES_PATH}${item.photoUrl}`} />
                  </IconBg>
                )
              })}
          </IconsWrapper>
        </InfoWrapper>
      </Infos>
    </Popout>
  )
}

export default LeagueInfoPopout
