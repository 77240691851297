import { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Stage } from '@pixi/react'
import { Popouts } from 'src/const/router'
import { PetClothes } from 'src/pixi/types/spine'
import { throttle } from 'src/utils/schedulers'
import { fixNames } from 'utils/i18/fixItemTypes'

import { Menu } from 'components/base'
import { Entity, EntityPet, StageWithMaskWrapper } from 'components/pixi'

import {
  BaseEgg,
  BaseEssence,
  BaseSkin,
  Biome,
  EggItemType,
  EggStatus,
  PetRes,
  PopulatedEgg,
  PopulatedUserDonate,
  PopulatedUserEssence,
  PopulatedUserSkinPet,
  UserDonate,
  UserEssence,
  UserSkinPet,
} from '@gatto/shared'

import { API_URL, STATIC_IMAGES_PATH } from '../../../config/constants'
import useScroll from '../../../hooks/useScroll'

import StorageItem from './StorageItem'

const StorageObserver = styled.div`
  height: 1px;
  width: 100%;
  //transform: rotate(90deg);
  background-color: transparent;
  z-index: -1;
`

const PAGE_HEADER_OFFSET = 200

type ItemType = {
  name?: string
  category?: string
  description?: string
  price?: number
  photoUrl?: string
  biom?: string
  biomImage?: string
  evolution?: number
  evolutionImage?: string
  timeToHatch?: string
  timeToHatchImage?: string
  experience?: number
  expierienceImage?: string
  level?: number
  levelImage?: string
  stats?: Array<{ icon: string; value: number }>
  status?: string
}

type EssenceItem = {
  _id: string
  owner: number
  essence: {
    _id: string
    name: string
    photoUrl: string
    description: string
    itemType: string
  }
}

type DonateItem = {
  _id: string
  user: number
  donate: {
    _id: string
    name: string
    photoUrl: string
    description: string
    itemType: string
  }
}

export type NFTItem = {
  _id: string
  owner: number
  status: string
  name: string
  level: number
  evolution: number
  basePet: UserSkinPet
  nft: {
    imageUrl: string
    contractAddress: string
    localPath: string
    mintedAt: string
  }
}

type PetItem = PetRes

type EggItem = PopulatedEgg

const petGuard = (item: object): item is PetItem => {
  return 'basePet' in item
}

const skinGuard = (item: object): item is PopulatedUserSkinPet => {
  return 'skin' in item
}

const eggGuard = (item: object): item is EggItem => {
  return 'baseEgg' in item
}

const donateGuard = (item: object): item is PopulatedUserDonate => {
  return 'donate' in item
}

const essenceGuard = (item: object): item is PopulatedUserEssence => {
  return 'essence' in item
}

const nftGuard = (item: ItemType): item is NFTItem => {
  return 'nft' in item && item.status === 'nft'
}

interface StorageProps {
  items:
    | ItemType[]
    | EggItem[]
    | PopulatedUserDonate[]
    | PopulatedUserEssence[]
    | PopulatedUserSkinPet[]
    | NFTItem[]
  sortBy: string | undefined
  getShopGoods: () => void
  isShouldWork: boolean
  limit: number
}

const StorageMenu: FC<StorageProps> = ({
  items,
  sortBy,
  getShopGoods,
  isShouldWork,
  limit,
}) => {
  const { t } = useTranslation()
  const [list, setList] = useState<ReactElement[]>()
  const [entitiesList, setEntitiesList] = useState<ReactElement[]>()
  useEffect(() => {
    if (sortBy) {
      const entities: ReactElement[] = []
      setList(
        items &&
          items.map((item, index) => {
            if (eggGuard(item)) {
              const egg = fixNames(t, item.baseEgg) as BaseEgg
              return (
                <StorageItem
                  key={item._id.toString()}
                  name={egg.name}
                  image={item.baseEgg.photoUrl}
                  category={egg.itemType as EggItemType}
                  props={{
                    localId: item._id.toString(),
                    id: egg._id.toString(),
                    type: egg.itemType,
                    biom: egg.allowedRegion,
                    evolution: item.metaData?.evolution,
                    isHatched: item.hatching?.isHatched,
                    isInStorage: item.status === EggStatus.InStorage,
                  }}
                />
              )
            }

            if (essenceGuard(item)) {
              const essence = fixNames(t, item.essence)
              return (
                <StorageItem
                  key={item._id.toString()}
                  name={essence.name}
                  image={item.essence.photoUrl}
                  category={'essence'}
                  props={{
                    id: essence._id.toString(),
                    type: essence.itemType,
                  }}
                />
              )
            }

            if (donateGuard(item)) {
              const donate = fixNames(t, item.donate)
              return (
                <StorageItem
                  key={donate._id.toString()}
                  name={donate.name}
                  image={item.donate.photoUrl}
                  category={donate.itemType as keyof Popouts}
                  props={{
                    id: donate._id.toString(),
                    type: item.donate.itemType,
                  }}
                />
              )
            }

            if (nftGuard(item)) {
              return (
                <StorageItem
                  key={index}
                  name={'' + item?.name}
                  image={'/uploads/' + item.nft.localPath}
                  category={'nft'}
                  props={{
                    name: item?.name,
                    type: 'pet',
                    level: item?.level,
                    evolution: item?.evolution,
                    pet: item,
                    image: API_URL + '/uploads/' + item.nft.localPath,
                  }}
                />
              )
            }

            if (petGuard(item)) {
              entities.push(
                <Entity
                  reactTracking={{
                    elementId: `petStorageItem_` + index,
                    stageId: 'storageStage',
                    scrollableElementId: 'menu',
                  }}
                  shadow={false}
                  entity={
                    item.basePet && (
                      <EntityPet
                        basePet={item.basePet}
                        squeeze={false}
                        x={85}
                        y={150}
                        scale={0.75}
                        skins={item.userSkinPets?.[0]?.skinData}
                      />
                    )
                  }
                />,
              )

              return (
                <StorageItem
                  id={`petStorageItem_` + index}
                  key={index}
                  name={item.name}
                  category={'pet'}
                  props={{
                    id: `petStorageItem_` + index,
                    localId: item._id.toString(),
                    petName: item.name,
                    petLevel: item.level,
                    petBiome: item.basePet.allowedRegion,
                    petEvolution: item.evolution,
                    basePet: item.basePet,
                    petChars: item.chars,
                    skins: item.userSkinPets?.[0]?.skinData,
                  }}
                />
              )
            }

            if (skinGuard(item)) {
              const skin = fixNames(t, item.skin) as BaseSkin
              return (
                <StorageItem
                  key={index}
                  name={skin.name}
                  category={skin.itemType}
                  image={item.skin.photoUrl}
                  props={{
                    name: skin.name,
                    description: skin.description,
                    image: skin.photoUrl,
                    rarity: skin.rarity,
                    id: item._id.toString(),
                  }}
                />
              )
            }

            return <></>
          }),
      )
      setEntitiesList(entities)
    }
  }, [items])

  const observer = useRef(null)

  useEffect(() => {
    menu.current?.scroll({ top: 0 })
  }, [sortBy])

  const menu = useRef<HTMLDivElement>(null)
  useScroll(menu, observer, limit, getShopGoods, items?.length, isShouldWork)

  return (
    <>
      {items.length ? (
        <>
          <Menu
            id="menu"
            ref={menu}
            ltRow={items.length < Math.floor(window.innerWidth / 180)}
          >
            {list}

            <StorageObserver ref={observer} />
          </Menu>

          <StageWithMaskWrapper offsetY={PAGE_HEADER_OFFSET}>
            <Stage
              id="storageStage"
              width={window.innerWidth}
              height={window.innerHeight - PAGE_HEADER_OFFSET}
              options={{
                backgroundAlpha: 0,
                antialias: true,
                resolution: window.devicePixelRatio || 1,
                autoDensity: true,
              }}
            >
              {entitiesList}
            </Stage>
          </StageWithMaskWrapper>
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default StorageMenu
export { StorageObserver }
