import { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { Stage } from '@pixi/react'
import { Spinner } from '@vkontakte/vkui'
import { GenIconGray } from 'src/config/assets'
import { API_URL, STATIC_IMAGES_PATH } from 'src/config/constants'
import usePlaceEgg from 'src/hooks/usePlaceEgg'
import usePlacePet from 'src/hooks/usePlacePet'
import { makeRequest } from 'src/utils/api'
import { getTimeFormatted } from 'utils/getTimeFormatted'

import { Popout, StatusType } from 'components/base/Popout'
import { Entity, EntityPet } from 'components/pixi'

import { LOCALES } from '@gatto/locales'
import { PetRes, PopulatedEgg, PopulatedUserRegion } from '@gatto/shared'

import EggAndPetCardList, { CardItem, PetCardItem } from './EggAndPetCardList'

const ButtonIcon = styled.div`
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  text-transform: lowercase;
`

const EggSubtitle = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 2px;
`

const SubtitleImg = styled.img`
  width: 10.605px;
  height: 12px;
`

const EggsAndPets: FC = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('eggs')
  const [status, setStatus] = useState<StatusType>()
  const [entitiesList, setEntitiesList] = useState<ReactElement[]>()
  const placePet = usePlacePet()
  const placeEgg = usePlaceEgg()

  const [items, setItems] = useState<{
    eggs: CardItem[]
    pets: PetCardItem[]
  }>({
    eggs: [],
    pets: [],
  })

  const [isFetching, setIsFetching] = useState(false)
  const { state } = useLocation()
  const popoutRef = useRef<HTMLDivElement>(null)

  const region: PopulatedUserRegion | undefined = state && state.region
  const regionId = region && region._id

  useEffect(() => {
    if (!region) {
      return
    }

    const fetchPets = async () => {
      setIsFetching(true)

      try {
        const tempEntities: ReactElement[] = []
        const items = (await makeRequest('warehouseGoods.get', {
          type: 'pets_to_place',
        })) as PetRes[]
        if (!popoutRef?.current) return
        const { x, y } = popoutRef.current.getBoundingClientRect()
        console.info(y)
        const petItems: PetCardItem[] = items
          .filter(
            (item) => item.basePet.allowedRegion === region.baseRegion.biome,
          )
          .map((pet, index) => {
            tempEntities.push(
              <Entity
                entity={
                  pet.basePet && (
                    <EntityPet
                      basePet={pet.basePet}
                      squeeze={false}
                      x={60}
                      y={100}
                      scale={0.5}
                      skins={pet.userSkinPets[0]?.skinData}
                    />
                  )
                }
                reactTracking={{
                  elementId: 'petContainer' + index,
                  stageId: 'eggsAndPetsStage',
                  scrollableElementId: 'cardScroll',
                }}
                shadow={false}
              />,
            )
            return {
              title: pet.name,
              buttonText: t(LOCALES.choose),
              id: 'petContainer' + index,
              img: <div style={{ width: '45px', height: '89px' }} />,
              isGrayBorder: true,
              onClick: () =>
                setTimeout(() => {
                  if (location.search === '?popout=eggsAndPets') {
                    placePet({ pet_id: pet._id, isPlacedFromMap: false })
                  }
                }, 200),
            }
          })

        setEntitiesList(tempEntities)

        setItems((prev) => ({
          ...prev,
          pets: petItems,
        }))
      } catch (error) {
        // empty
      } finally {
        setIsFetching(false)
      }
    }

    const fetchEggs = async () => {
      setIsFetching(true)
      try {
        const items = (await makeRequest('warehouseGoods.get', {
          type: 'eggs',
        })) as PopulatedEgg[]
        const eggItems: CardItem[] = items
          .filter(
            (item) =>
              !item.hatching &&
              item.baseEgg.allowedRegion === region.baseRegion.biome,
          )
          .map((egg) => {
            return {
              title: egg.baseEgg.name,
              buttonText: t(LOCALES.choose),
              buttonIcon: (
                <ButtonIcon>
                  {getTimeFormatted(t, egg.baseEgg.hatchingTime).replace(
                    /\s|\.|(^00[а-яa-z])|^0|(00[а-яa-z])/gm,
                    '',
                  )}
                </ButtonIcon>
              ),
              img: `${STATIC_IMAGES_PATH}${egg.baseEgg.photoUrl}`,
              isGrayBorder: true,
              subtitle: (
                <EggSubtitle>
                  {egg.metaData?.evolution || 1}
                  <SubtitleImg src={GenIconGray} />
                </EggSubtitle>
              ),
              onClick: () =>
                placeEgg({ egg_id: egg._id, isPlacedFromMap: false }),
            }
          })

        setItems((prev) => ({
          ...prev,
          eggs: eggItems,
        }))
      } catch (error) {
        // empty
      } finally {
        setIsFetching(false)
      }
    }

    if (activeTab === 'eggs') fetchEggs()
    if (activeTab === 'pets') fetchPets()
  }, [placeEgg, region, popoutRef, activeTab])

  const tabItems = [
    { name: t(LOCALES.itemTypeEggs), type: 'eggs' },
    { name: t(LOCALES.itemTypePets), type: 'pets' },
  ]

  const header = [
    { name: t(LOCALES.itemTypeEggs), type: 'eggs' },
    { name: t(LOCALES.itemTypePets), type: 'pets' },
  ]

  return (
    <Popout
      popoutRef={popoutRef}
      type="tabs"
      tabItems={tabItems}
      width={'100%'}
      maxWidth={'373px'}
      headerPosition={'left'}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      position="bottom"
      background={true}
      isFullScreen={false}
      header={header}
      status={status}
      setStatus={setStatus}
    >
      <div style={{ height: ' 200px' }}>
        {isFetching && <Spinner size="medium" />}
        {!isFetching && (
          <EggAndPetCardList
            activeTab={activeTab}
            cards={items[activeTab as 'eggs' | 'pets']}
          />
        )}
      </div>

      <Stage
        id="eggsAndPetsStage"
        style={{ position: 'absolute', pointerEvents: 'none' }}
        width={333}
        height={204}
        options={{
          backgroundAlpha: 0,
          antialias: true,
          resolution: window.devicePixelRatio || 1,
          autoDensity: true,
        }}
      >
        {activeTab === 'pets' && entitiesList}
      </Stage>
    </Popout>
  )
}

export default EggsAndPets
