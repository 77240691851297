import { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { Spinner } from '@vkontakte/vkui'
import { API_URL, STATIC_IMAGES_PATH } from 'src/config/constants'
import useScroll from 'src/hooks/useScroll'

import { Menu } from 'components/base'
import { StorageObserver as StoreObserver } from 'components/page'

import {
  BaseSkin,
  CurrencyType,
  DonateItemType,
  EggItemType,
  EssenceItemType,
  GoodsGetRes,
  ItemType,
  SkinItemType,
  SkinRarity,
} from '@gatto/shared'

import MenuItem from './StoreMenuItem'

type propsType = {
  id: string
  name: string
  price: number
  image: string
  description: string
  currency: CurrencyType
  goodId: string
  itemType:
    | ItemType
    | EssenceItemType
    | DonateItemType
    | SkinItemType
    | EggItemType
  rarity?: SkinRarity
}

const skinGuard = (item: object): item is BaseSkin => {
  return 'rarity' in item
}

interface MenuProps {
  items: GoodsGetRes
  sortBy: string
  getShopGoods: () => void
  isShouldWork: boolean
  limit: number
}

const StoreMenu: FC<MenuProps> = ({
  items,
  sortBy,
  getShopGoods,
  isShouldWork,
  limit,
}) => {
  const [list, setList] = useState<ReactElement[]>()

  useEffect(() => {
    if (sortBy) {
      setList(
        items &&
          items?.map((item) => {
            const props: propsType = {
              id: item._id.toString(),
              name: item.name,
              description: item.description,
              image: `${STATIC_IMAGES_PATH}${item.photoUrl}`,
              price: item.price,
              currency: item.currency, // тип валюты
              itemType: item.itemType, // тип товара
              goodId: item._id.toString(),
            }

            if (skinGuard(item)) {
              props.rarity = item.rarity
            }

            return (
              <MenuItem
                key={item._id.toString()}
                name={item.name}
                price={item.price}
                image={`${STATIC_IMAGES_PATH}${item.photoUrl}`}
                currency={item.currency}
                props={props}
              />
            )
          }),
      )
    } else {
      setList(
        items &&
          items.map((item) => {
            const props: propsType = {
              id: item._id.toString(),
              name: item.name,
              description: item.description,
              image: item.photoUrl,
              price: item.price,
              currency: item.currency,
              itemType: item.itemType,
              goodId: item._id.toString(),
            }

            if (skinGuard(item)) {
              props.rarity = item.rarity
            }

            return (
              <MenuItem
                key={item._id.toString()}
                name={item.name}
                price={item.price}
                image={item.photoUrl}
                currency={item.currency}
                props={props}
              />
            )
          }),
      )
    }
  }, [items, sortBy])

  const observer = useRef(null)

  useEffect(() => {
    menu.current?.scroll({ top: 0 })
  }, [sortBy])

  const menu = useRef<HTMLDivElement>(null)

  useScroll(menu, observer, limit, getShopGoods, items?.length, isShouldWork)

  return (
    <>
      {list ? (
        <Menu
          ref={menu}
          ltRow={list.length < Math.floor(window.innerWidth / 180)}
        >
          {list}
          <StoreObserver ref={observer} />
        </Menu>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default StoreMenu
