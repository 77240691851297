import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { MINI_APP } from 'src'
import {
  API_URL,
  APP_LANG,
  STATIC_IMAGES_PATH,
  TG_CHAT_LINK_ENG,
  TG_CHAT_LINK_RUS,
  TG_GROUP_LINK,
  VK_CHAT_LINK,
  VK_GROUP_LINK,
} from 'src/config/constants'
import { usePopout } from 'src/hooks'
import { openUrl } from 'utils/openURL'

import { LOCALES } from '@gatto/locales'
import { Platform } from '@gatto/shared'

// type news = Array<{
//   title: string
//   imageUrl: string
//   id: number
//   size: {
//     x: number
//     y: number
//   }
// }>

export const ImageContainer = styled.div`
  display: grid;
  gap: 10px;
  padding: 24px 20px 10px 20px;
  justify-content: center;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 171px) 1fr;
  grid-auto-columns: 171px;
  grid-auto-rows: 171px;
  overflow-y: scroll;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(62, 112, 242, 0.99) 3%,
    rgba(62, 112, 242, 0.99) 97%,
    rgba(255, 255, 255, 0) 100%
  );
  &::-webkit-scrollbar {
    display: none;
  }
`

export const TileCard = styled.div`
  border-radius: 20px;
  &:hover {
    cursor: pointer;
  }
`

const News: FC = () => {
  const openSettingsPopout = usePopout('settingsModal', false)
  const openBonusPopout = usePopout('bonus', false)
  const openRefferalPopout = usePopout('referralSystem', false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const sortByInStore = 'donates'
  const news = [
    {
      id: 5,
      title: t(LOCALES.bonuses),
      imageUrl:
        APP_LANG === 'ru'
          ? `${STATIC_IMAGES_PATH}/uploads/news/bonus.png`
          : `${STATIC_IMAGES_PATH}/uploads/news/bonuses_eng.png`,
      callback: () => {
        if (MINI_APP === Platform.VK) {
          openBonusPopout()
        }

        if (MINI_APP === Platform.TG) {
          openRefferalPopout()
        }
      },
      size: {
        x: 2,
        y: 1,
      },
    },
    {
      id: 6,
      title: t(LOCALES.bonuses),
      imageUrl: `${STATIC_IMAGES_PATH}/uploads/news/chat.jpg`,
      callback: () =>
        openUrl(
          MINI_APP === Platform.VK
            ? VK_CHAT_LINK
            : APP_LANG === 'ru'
            ? TG_CHAT_LINK_RUS
            : TG_CHAT_LINK_ENG,
        ),
      size: {
        x: 1,
        y: 1,
      },
    },
    {
      id: 3,
      title: t(LOCALES.season),
      imageUrl:
        APP_LANG === 'ru'
          ? `${STATIC_IMAGES_PATH}/uploads/news/season.png`
          : `${STATIC_IMAGES_PATH}/uploads/news/trial_kvahilles_eng.png`,
      callback: () =>
        openUrl(
          'https://gatto-official.gitbook.io/white-paper/kto-takie-gattomony/geimplei/iventy',
        ),
      size: {
        x: 1,
        y: 2,
      },
    },
    {
      id: 2,
      title: t(LOCALES.news),
      imageUrl: `${STATIC_IMAGES_PATH}/uploads/news/news2.png`,
      callback: () =>
        openUrl(MINI_APP === Platform.VK ? VK_GROUP_LINK : TG_GROUP_LINK),
      size: {
        x: 1,
        y: 1,
      },
    },
    {
      id: 1,
      title: t(LOCALES.best_pet),
      imageUrl:
        APP_LANG === 'ru'
          ? `${STATIC_IMAGES_PATH}/uploads/news/rating2.png`
          : `${STATIC_IMAGES_PATH}/uploads/news/best_pet_eng.png`,
      callback: () => navigate('/rating'),
      size: {
        x: 2,
        y: 1,
      },
    },
    {
      id: 4,
      title: t(LOCALES.settings),
      imageUrl: `${STATIC_IMAGES_PATH}/uploads/news/settings2.png`,
      callback: () => openSettingsPopout(),
      size: {
        x: 1,
        y: 1,
      },
    },
  ]

  return (
    <ImageContainer>
      {news.map((item) => {
        const style = {
          gridArea: `1 / 1 / span ${item.size.y} / span ${item.size.x}`,
          gridColumn: `span ${item.size.x}`,
          gridRow: `span ${item.size.y}`,
          background: `url(${item.imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }

        return (
          <TileCard
            style={style}
            onClick={() => item.callback()}
            key={item.id}
          />
        )
      })}
    </ImageContainer>
  )
}

News.displayName = 'News'
export default News
