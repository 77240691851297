import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Icon16Done, Icon16User } from '@vkontakte/icons'
import bridge from '@vkontakte/vk-bridge'
import { Avatar, Snackbar } from '@vkontakte/vkui'
import { Popouts } from 'const/router'
import { MINI_APP } from 'src'
import { CoinIcon } from 'src/config/assets'
import {
  API_URL,
  STATIC_IMAGES_PATH,
  VK_MINI_APP_LINK,
} from 'src/config/constants'
import { makeRequest } from 'utils/api'
import copyTextToClipboard from 'utils/copyTextToClipboard'

import copy from 'assets/icons/Copy.png'
import share from 'assets/icons/share.png'
import DiamondPaw from 'assets/svg/diamond_paw.svg'
import { Button, Header, Popout, StatusType, Subheader } from 'components/base'
import { useAppSelector } from 'store/index'
import { userIdSelector } from 'store/user'

import { LOCALES } from '@gatto/locales'
import { Platform } from '@gatto/shared'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeadersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`

const Icon = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-bottom: 12px;
`
const StatisticsTitle = styled.div`
  margin-top: 15px;
  color: #000;
  font-family: Nunito;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  width: 100%;
`

const Statistics = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 11px;
  border-radius: 12px;
  background-color: #f6feff;
`

const StatisticBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`

const StatisticIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: var(
    --gradient-sky-10,
    linear-gradient(
      180deg,
      rgba(13, 178, 255, 0.1) 0%,
      rgba(11, 152, 249, 0.1) 100%
    ),
    #fff
  );
`

const StatisticIcon = styled.img`
  width: 16px;
  height: 16px;
`

const StatisticBlockTitle = styled.div`
  color: #000;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
const StatisticBlockSubtitle = styled.div`
  color: #888;
  font-family: Nunito;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
const Rectangle = styled.div`
  width: 8px;
  height: 4px;
  border-radius: 32px;
  background: linear-gradient(180deg, #0db2ff 0%, #0b98f9 100%);
`

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`
const ButtonIcon = styled.img`
  width: 32px;
  height: 32px;
`

type ReferralSystemPopoutProps = Popouts['referralSystem']

const ReferralSystemPopout: FC<ReferralSystemPopoutProps> = () => {
  const userId = useAppSelector(userIdSelector)
  const [status, setStatus] = useState<StatusType>()
  const [snackbar, setSnackbar] = useState<React.ReactNode | null>(null)
  const [invitedFriendsCount, setInvitedFriendsCount] = useState(0)
  const { t } = useTranslation()
  const openSnackbar = () => {
    if (snackbar) return
    setSnackbar(
      <Snackbar
        onClose={() => setSnackbar(null)}
        before={
          <Avatar
            size={24}
            style={{ background: 'var(--vkui--color_background_accent)' }}
          >
            <Icon16Done fill="#fff" width={14} height={14} />
          </Avatar>
        }
      >
        Ссылка скопирована
      </Snackbar>,
    )
  }

  const handleShare = () => {
    bridge.send('VKWebAppShare', {
      link: `${VK_MINI_APP_LINK}#${userId}`,
    })
  }

  const handleCopy = async () => {
    try {
      if (MINI_APP === Platform.VK) {
        bridge.send('VKWebAppCopyText', {
          text: `${VK_MINI_APP_LINK}#${userId}`,
        })
      }

      if (MINI_APP === Platform.TG) {
        copyTextToClipboard(
          `https://t.me/gatto_gamebot/Gatto?startapp=${userId}`,
        )
      }

      openSnackbar()
    } catch (e) {
      console.info(e)
    }
  }

  const handleStory = async () => {
    if (MINI_APP === Platform.VK) {
      bridge.send('VKWebAppShowStoryBox', {
        background_type: 'image',
        url: `${STATIC_IMAGES_PATH}/uploads/news/story.png`,
        attachment: {
          text: 'game',
          type: 'url',
          url: `${VK_MINI_APP_LINK}#${userId}`,
        },
      })
    }
  }

  useEffect(() => {
    if (MINI_APP === Platform.TG) {
      makeRequest('user.getBonusesInfo', {}).then(({ invitedFriendsCount }) => {
        setInvitedFriendsCount(invitedFriendsCount)
      })
    }
  }, [])

  return (
    <Popout
      background={true}
      isFullScreen={false}
      setStatus={setStatus}
      status={status}
      type="default"
      width="353px"
    >
      <Wrapper>
        <Icon src={MINI_APP === Platform.VK ? DiamondPaw : CoinIcon} />
        <HeadersWrapper>
          <Header>{t(LOCALES.referral_system)}</Header>
          <Subheader>
            {t(
              MINI_APP === Platform.VK
                ? LOCALES.get3hardEachFriend
                : LOCALES.get100coinsEachFriend,
            )}
          </Subheader>
        </HeadersWrapper>
        <StatisticsTitle>{t(LOCALES.statistics)}</StatisticsTitle>
        <Statistics>
          <StatisticBlock>
            <StatisticIconWrapper>
              <StatisticIcon
                src={MINI_APP === Platform.VK ? DiamondPaw : CoinIcon}
              />
            </StatisticIconWrapper>
            <StatisticBlockTitle>
              {invitedFriendsCount * (MINI_APP === Platform.VK ? 3 : 150) || 0}
            </StatisticBlockTitle>
            <StatisticBlockSubtitle>
              {t(LOCALES.you_earned)}
            </StatisticBlockSubtitle>
            <Rectangle />
          </StatisticBlock>
          <StatisticBlock>
            <StatisticIconWrapper>
              <Icon16User />
            </StatisticIconWrapper>
            <StatisticBlockTitle>
              {invitedFriendsCount || 0}
            </StatisticBlockTitle>
            <StatisticBlockSubtitle>
              {t(LOCALES.you_invited)}
            </StatisticBlockSubtitle>
            <Rectangle />
          </StatisticBlock>
        </Statistics>
        <ButtonsWrapper>
          {MINI_APP === Platform.VK && (
            <>
              <Button
                variant="success"
                width="100%"
                onClick={() => handleStory()}
              >
                {t(LOCALES.goHistory)}
              </Button>

              <Button
                width="auto"
                variant="primary"
                onClick={() => handleShare()}
              >
                {<ButtonIcon src={share} />}
              </Button>
            </>
          )}
          <Button width="auto" variant="primary" onClick={() => handleCopy()}>
            {<ButtonIcon src={copy} />}
          </Button>
        </ButtonsWrapper>
        {snackbar}
      </Wrapper>
    </Popout>
  )
}

export default ReferralSystemPopout
