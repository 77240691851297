import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  NavigateFunction,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import styled from '@emotion/styled'
import { Container, Stage } from '@pixi/react'
import bridge from '@vkontakte/vk-bridge'
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app'
import { MODAL_ANIMATION_MS } from 'const/index'
import { Action, createBrowserHistory } from 'history'
import { Spritesheet } from 'pixi.js'
import { Viewport as BaseViewport } from 'pixi-viewport'
import {
  BlockTitle,
  Cross,
  Currency,
  PolygonPaw,
} from 'popouts/PetCross/PetCrossPopout'
import { MINI_APP } from 'src'
import { MAP_SPRITESHEET_PATH } from 'src/config/assets'
import { usePopout } from 'src/hooks'
import useOnBoarding from 'src/hooks/router/useOnBoarding'
import useIsFirstRender from 'src/hooks/useIsFirstRender'
import useLoadAsset from 'src/hooks/useLoadAsset'
import useMap from 'src/hooks/useMap'
import usePlaceEgg from 'src/hooks/usePlaceEgg'
import usePlacePet from 'src/hooks/usePlacePet'
import useScreenDimensions from 'src/hooks/useScreenDimensions'
import Position, { StringifiedPosition } from 'src/types/Position'
import Tile from 'src/types/Tile'
import { generateTiles } from 'src/utils/generateMapTiles'
import {
  positionToStringifiedPosition,
  stringifiedPositionToPosition,
} from 'src/utils/position'
import { makeRequest } from 'utils/api'
import debounce from 'utils/debounce'
import { getLocationSearch } from 'utils/getLocationSearch'
import { getVKStorage, setVKStorage } from 'utils/vkStorage'

import EggIconImage from 'assets/svg/egg/eggIcon.svg'
import CrossIcon from 'assets/svg/popouts/cross.svg'
import ApplyIcon from 'assets/svg/popouts/essence-apply.svg'
import Gen from 'assets/svg/popouts/gen.png'
import PolygonIcon from 'assets/svg/popouts/polygon.svg'
import { CloudsBackground } from 'components/Background'
import { Button, Wallet } from 'components/base'
import {
  Entity,
  EntityEgg,
  EntityPet,
  EntityRays,
  ResizableStage,
  Viewport,
} from 'components/pixi'
import { useAppDispatch, useAppSelector } from 'store'
import { setOnboarding } from 'store/onboarding'
import region, { setRegionId, updateRegionPetSkins } from 'store/region'
import { setIsApplied, setIsEssence, setIsTabbarHidden } from 'store/storage'
import { updatePet, updateRegions, updateUserRegionPetSkins } from 'store/user'
import { getWaitLobby, setAlreadyConnectedOnce } from 'store/waitLobby'

import { LOCALES } from '@gatto/locales'
import {
  BasePet,
  GameType,
  Platform,
  PopulatedPet,
  PopulatedPetWithSkins,
} from '@gatto/shared'

import HomeIcon from '../../assets/svg/other/house.svg'
import {
  API_URL,
  LOCATION_HASH,
  STATIC_IMAGES_PATH,
} from '../../config/constants'
import {
  OnboardingCross,
  OnboardingStadium,
  OnboardingStore,
  OnboardingWelcome,
} from '../../config/onboardings'

import SelectedTilesOutline from './SelectedTilesOutline'
import TileSprite from './TileSprite'
import {
  getLargeTileDimensions,
  getStageDimensions,
  getTileDimensions,
} from './utils'

export type TileSelectionType = 'outline' | 'brightness'

export interface SelectedTile {
  id: string | undefined
  selectionTypes: Set<TileSelectionType>
  position: Position
}

export type SelectedTiles = Map<StringifiedPosition, SelectedTile>

const MapWallet = styled(Wallet)`
  position: absolute;
  background-color: #3070aa30;
  z-index: 11;
  background: linear-gradient(
      0deg,
      rgba(48, 112, 170, 0.19) 0%,
      rgba(48, 112, 170, 0.19) 100%
    ),
    linear-gradient(
      355deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
  box-shadow: 0px -4px 0px 0px rgba(80, 131, 130, 0.25) inset;
`

// const Root = styled.div({
//   height: '100%',
//   width: '100%',
//   position: 'absolute',
//   backgroundPosition: 'center',
//   backgroundRepeat: 'no-repeat',
//   backgroundSize: 'cover',
//   background:
//     'linear-gradient(360deg, rgba(255,255,255,1) 0%,  rgba(0,232,255,0.8855917366946778) 100%)',
// })
const Root = styled.div<{ type: 'default' | 'halloween' }>`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: ${(prop) =>
    prop.type === 'default'
      ? 'linear-gradient(360deg, rgba(255,255,255,1) 0%,  rgba(0,232,255,0.8855917366946778) 100%)'
      : 'linear-gradient(180deg, rgba(176,41,132,1) 0%, rgba(246,100,13,1) 35%, rgba(253,217,196,1) 100%)'};
`

const SelectedPet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 200px;
  background: linear-gradient(
    180deg,
    rgba(122, 202, 206, 0.6) 25.52%,
    rgba(145, 220, 250, 0) 100%
  );
  //background-color: red;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
`

const SuggestTitle = styled.div<{ mb?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 319px;
  margin-bottom: ${(props) => (props.mb ? props.mb : '10px')};
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`

const Essence = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 70px;
  height: 80px;
  margin-top: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset;
  position: absolute;
  top: 90px;
`

const EssenceImage = styled.img`
  width: 29.222px;
  height: 37px;
`

const HomeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 5px;
  height: 55px;
  gap: 10px;
  border-radius: 11px;
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`

const HomeButton = styled.div<{ isClicked?: boolean }>`
  display: flex;
  width: 48px;
  height: 50px;
  padding: 10px 10px 13px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #f0ffdb;
  box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.15) inset;
  position: ${(props) => props.isClicked && 'absolute'};
  bottom: ${(props) => props.isClicked && '5%'};
  left: ${(props) => props.isClicked && '50%'};
  transform: ${(props) => props.isClicked && 'translate(-50%, -50%)'};
`

const GenInfo = styled.div<{ ml?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  text-align: center;
  color: #000;
  font-size: 14px;
  margin-left: ${(props) => (props.ml ? props.ml : 0)};
  font-style: normal;
  font-weight: 600;
  line-height: 19.103px;
`

const IconBlock = styled.div<{ pr?: string; mb?: string; w?: string }>`
  display: flex;
  justify-content: start;
  width: ${(props) => props.w && props.w};
  align-items: start;
  text-align: start;
  flex-direction: row;
  margin-left: 2px;
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  padding-right: ${(props) => (props.pr ? props.pr : '10px')};
`

const BlockText = styled.div<{ width?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: ${(props) => (props.width ? props.width : '60px')};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CrossBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 82px;
  left: 74px;
  transform: translate(-50%, -50%);
`

const PolygonBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Apply = styled.img`
  width: 13.19px;
  height: 15px;
  position: absolute;
`

const HomeImage = styled.img`
  width: 28.017px;
  height: 27.487px;
  cursor: pointer;
`

const EggIcon = styled.img`
  width: 28.017px;
  height: 27.487px;
  flex-shrink: 0;
`

export type SelectedTilesActions = {
  set: (key: StringifiedPosition, value: SelectedTile) => void
  remove: (key: StringifiedPosition) => void
  clear: () => void
}

export type TileProps = {
  setIsPetSelected: React.Dispatch<React.SetStateAction<boolean>>
  setFirstPet: React.Dispatch<
    React.SetStateAction<PopulatedPetWithSkins | undefined | null>
  >
  setPetIds: React.Dispatch<React.SetStateAction<string | undefined>>
  setIsCleared: React.Dispatch<React.SetStateAction<boolean>>
  firstPet: PopulatedPetWithSkins | undefined | null
  isPressed: boolean
  isCleared: boolean
  isEssence: boolean | null
  deleteBiomRef: React.MutableRefObject<string | undefined>
  setDeleteBiom: React.Dispatch<React.SetStateAction<string | undefined>>
  deleteBiom: string | undefined
  navigate: NavigateFunction
}

type TilesProps = {
  tiles: Tile[][]
  onClick: (tile: Tile) => void
  selectedTilesActions: SelectedTilesActions
  selectedTiles: SelectedTiles
  tileProps: TileProps
  basePet?: BasePet | undefined
  pet?: PopulatedPetWithSkins | undefined | null
  placing?: boolean
  allowedRegion?: string | null
}

const Tiles: React.FC<TilesProps> = ({
  tiles,
  selectedTiles,
  onClick,
  selectedTilesActions,
  tileProps,
  placing,
  allowedRegion,
}) => {
  const { user } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const shouldPlayAppearAnimation = useIsFirstRender()
  const spritesheet = useLoadAsset<Spritesheet>(MAP_SPRITESHEET_PATH)
  const screenDimensions = useScreenDimensions()
  const stageDimensions = getStageDimensions(screenDimensions)
  const tilesAmount = tiles.length
  const tileDimensions = getTileDimensions(stageDimensions, tilesAmount)
  const shouldDimBrightness = selectedTiles.size > 0
  const largeTileDimensions = getLargeTileDimensions(
    stageDimensions,
    tilesAmount,
  )
  const [isPetAlone, setIsPetAlone] = useState<boolean>(true)

  const [petObject, setPetObject] = useState<{ [key: string]: number }>({})

  const pets = () => {
    const pet: { [key: string]: number } = {}
    if (user && user.regions) {
      user.regions.forEach((item) => {
        if (item.pet && item.pet.basePet && item.pet.level === 10) {
          const kind = item.pet.basePet.kind
          if (pet[kind]) {
            pet[kind] += 1
          } else {
            pet[kind] = 1
          }
        }
      })
    }
    setPetObject(pet)
  }

  useEffect(() => {
    makeRequest('skin.getAllPetsSkins', {}).then((res) => {
      res.forEach((item) =>
        dispatch(
          updateUserRegionPetSkins({
            petId: item.pet,
            skins: item.skin,
          }),
        ),
      )
    })
  }, [])

  useEffect(() => {
    pets()
  }, [user])

  useEffect(() => {
    if (location.search.indexOf('mode=cross') !== -1) {
      setIsPetAlone(true)
    }
  }, [location.search])

  // Без текстур не рендерим тайлы
  if (!spritesheet) return <></>

  return tiles.map((line, y) => {
    return line.map((tile, x) => {
      const region = user?.regions.find(
        (region) => region.position.x === x && region.position.y === y,
      )
      const pet = region?.pet as PopulatedPetWithSkins
      const egg = region?.egg

      const eggSrc = egg ? `${STATIC_IMAGES_PATH}${egg.baseEgg.photoUrl}` : ''
      const isHatched = Boolean(
        egg?.hatching?.isHatched ||
          (egg?.hatching?.hatchingTimeout &&
            Date.now() >= egg?.hatching?.hatchingTimeout),
      )

      const stringifiedPosition = positionToStringifiedPosition(tile.position)
      const selectedTile = selectedTiles?.get(stringifiedPosition)

      if (
        location.search.indexOf('mode=cross') !== -1 &&
        pet?.level === 10 &&
        !tileProps.isPressed &&
        tile?.pet &&
        petObject[tile.pet.basePet?.kind] !== 1
      ) {
        if (isPetAlone) {
          setIsPetAlone(false)
        }
        selectedTilesActions.set(positionToStringifiedPosition(tile.position), {
          id: tile.id,
          position: tile.position,
          selectionTypes: new Set(['brightness']),
        })
      }

      if (
        tileProps.firstPet &&
        pet?.basePet &&
        tileProps.isPressed &&
        !tileProps.isCleared &&
        location.search.indexOf('mode=cross') !== -1
      ) {
        if (
          tileProps.firstPet?.basePet.kind === pet.basePet?.kind &&
          tile.pet?.level === 10
        ) {
          selectedTilesActions.set(
            positionToStringifiedPosition(tile.position),
            {
              id: tile.id,
              position: tile.position,
              selectionTypes: new Set(['brightness']),
            },
          )
        }
      }

      if (pet?.basePet && pet?.level !== 10 && tileProps.isEssence) {
        selectedTilesActions.set(positionToStringifiedPosition(tile.position), {
          id: tile.id,
          position: tile.position,
          selectionTypes: new Set(['brightness']),
        })
      }

      if (tile.id && tileProps.deleteBiomRef.current) {
        selectedTilesActions.set(positionToStringifiedPosition(tile.position), {
          id: tile.id,
          position: tile.position,
          selectionTypes: new Set(['brightness']),
        })
      }

      return (
        <TileSprite
          key={stringifiedPosition}
          tile={tile}
          onpointertap={onClick.bind(null, tile)}
          tileProp={tileProps}
          selectionTypes={selectedTile?.selectionTypes}
          selectedTilesActions={selectedTilesActions}
          shouldDimBrightness={shouldDimBrightness}
          tileDimensions={tile.isLarge ? largeTileDimensions : tileDimensions}
          isSelected={!!selectedTile}
          pet={pet}
          petObject={petObject}
          isPetAlone={isPetAlone}
          placing={placing}
          allowedRegion={allowedRegion}
          egg={egg}
        >
          <Entity
            key={`${region?._id}`}
            scale={1.4}
            anchor={[0.5, 0.5]}
            x={200}
            y={200}
            appear={shouldPlayAppearAnimation}
            shadow={false}
            entity={
              <>
                {pet && (
                  <EntityPet
                    basePet={pet.basePet}
                    isEssenceMode={tileProps.isEssence}
                    isPetAlone={isPetAlone}
                    pet={pet}
                    skins={pet.skins}
                  />
                )}
                {egg && <EntityEgg image={eggSrc} scale={0.45} y={30} />}
              </>
            }
            rays={egg && isHatched && <EntityRays y={-30} />}
            isEssenceMode={tileProps.isEssence}
          />
        </TileSprite>
      )
    })
  })
}

export const MapPage: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector((root) => root.user)
  const { isEssenceApplying, essence } = useAppSelector(
    (state) => state.storage,
  )
  const history = createBrowserHistory()
  const [searchParams, setSearchParams] = useSearchParams()
  const [firstPet, setFirstPet] = useState<
    PopulatedPetWithSkins | undefined | null
  >(undefined)
  const [applyLoad, setApplyLoad] = useState<boolean>(false)
  const [petId, setPetId] = useState<string | undefined>(undefined)
  const [isPetSelected, setIsPetSelected] = useState<boolean>(false)
  const [isCleared, setIsCleared] = useState<boolean>(false)
  const [isEssence, setIsEssenceApplying] = useState<boolean | null>(
    isEssenceApplying,
  )
  const deleteBiomRef = useRef<string | undefined>()
  const [deleteBiom, setDeleteBiom] = useState<string>()
  const [placing, setPlacing] = useState<string | null>(
    searchParams.get('placing'),
  )
  const { setItem, getItems } = useCloudStorage()

  const [placingTileSelected, setPlacingTileSelected] = useState<boolean>(false)
  const [allowedRegion, setAllowedRegion] = useState<string | null>(
    searchParams.get('allowed_region'),
  )
  const [essenceClicked, setEssenceClicked] = useState<boolean>(false)
  const viewportRef = useRef<BaseViewport>(null)
  const tiles = useMemo(() => generateTiles(user?.regions), [user?.regions])
  const [pressedTile, setPressedTile] = useState<StringifiedPosition>()
  const [selectedTiles, selectedTilesActions] = useMap<
    StringifiedPosition,
    SelectedTile
  >()
  const location = useLocation()
  const navigate = useNavigate()
  const openPopout = usePopout('bioms', false, {
    selectedTile: pressedTile && stringifiedPositionToPosition(pressedTile),
  })
  const openSuccessAlert = usePopout('statusAlert', true, {
    type: 'success',
    subheader: t(
      placing === 'pet' ? LOCALES.settledSuccessPet : LOCALES.settledSuccessEgg,
    ),
  })
  const openCross = usePopout('petsCross', false)

  const placePet = usePlacePet()
  const placeEgg = usePlaceEgg()

  const dispatch = useAppDispatch()

  const tilesAmount = tiles.length
  const screenDimensions = useScreenDimensions()
  const stageDimensions = getStageDimensions(screenDimensions)
  const tileDimensions = getTileDimensions(stageDimensions, tilesAmount)

  const onboarding = useAppSelector((root) => root.onboarding)

  useEffect(() => {
    return () => {
      deleteBiomRef.current = 'stop timeout'
    }
  }, [])

  const HandleHome = () => {
    setIsCleared(true)
    setIsPetSelected(false)
    setIsEssenceApplying(false)
    setFirstPet(undefined)
    setPlacing(null)
    setPlacingTileSelected(false)
    setAllowedRegion(null)
    deleteBiomRef.current = undefined
    setDeleteBiom(undefined)
    dispatch(setIsEssence(false))
    dispatch(setIsTabbarHidden(false))
    selectedTilesActions.clear()
  }

  const handleUseEssence = async () => {
    //Активация эссенций + lvl up
    setApplyLoad(true)
    await makeRequest('essence.activate', {
      petId: firstPet?._id?.toString(),
      essenceId: essence?._id.toString(),
    }).then((res) => {
      dispatch(updatePet(res))
    })

    dispatch(setIsApplied(true))
    dispatch(setIsEssence(false))
    navigate('/storage', { replace: false })

    setApplyLoad(false)
  }

  const handleDeleteBiom = async () => {
    if (deleteBiom) {
      const { regions } = await makeRequest('region.deleteBiome', {
        id: deleteBiom,
      })
      dispatch(updateRegions(regions))
      HandleHome()
    }
  }

  const placeOnTile = async () => {
    const tile = selectedTiles.entries().next().value[1]

    if (placing === 'pet') {
      const pet_id = searchParams.get('pet_id')
      setSearchParams('/', { replace: true })
      if (!pet_id || !tile.id) return

      placePet({
        pet_id: pet_id,
        regionToPlace: tile.id,
        isPlacedFromMap: true,
      })
        .then(() => {
          setPlacing(null)
          setPlacingTileSelected(false)
          dispatch(setIsTabbarHidden(false))
          selectedTilesActions.clear()
          openSuccessAlert()
        })
        .catch((err) => {
          err
          setPlacing(null)
        })
    }
    if (placing === 'egg') {
      const egg_id = searchParams.get('egg_id')
      setSearchParams('/', { replace: true })
      if (!egg_id || !tile.id) return
      placeEgg({
        egg_id: egg_id,
        regionToPlace: tile.id,
        isPlacedFromMap: true,
      })
        .then(() => {
          setPlacing(null)
          setPlacingTileSelected(false)
          dispatch(setIsTabbarHidden(false))
          selectedTilesActions.clear()
          openSuccessAlert()
        })
        .catch((err) => {
          err
          setPlacing(null)
        })
    }
  }

  // Позиционируем контейнер с картой посередине экрана
  const containerX = stageDimensions.width / 2 - tileDimensions.width / 2
  const containerY =
    stageDimensions.height / 2 -
    (tileDimensions.height * tilesAmount) / 2 +
    tileDimensions.gapY * (tilesAmount - 1)

  const handleTileClick = useCallback(
    (tile: Tile) => {
      const isEvo = firstPet?.evolution === 10 && tile.pet?.evolution === 10
      if (location.search.indexOf('mode=cross') !== -1 && isPetSelected) {
        if (
          firstPet?.basePet.kind === tile.pet?.basePet.kind &&
          firstPet?.level === 10 &&
          tile.pet?.level === 10 &&
          !isEvo
        ) {
          if (firstPet?._id === tile.pet?._id) {
            return navigate(`/region/${tile.id}`)
          }
          if (firstPet && tile.pet && petId) {
            openCross({
              firstPet: firstPet,
              secondPet: tile.pet,
              firstPetID: petId,
              secondPetID: tile.pet._id.toString(),
            })
            setIsPetSelected(false)
          }
        } else return
      } else if (
        !isPetSelected &&
        location.search.indexOf('mode=cross') === -1 &&
        !isEssence &&
        !placing &&
        !deleteBiomRef.current &&
        tile.id
      ) {
        setIsPetSelected(false)
        dispatch(setRegionId(tile.id))
        return navigate(`/region/${tile.id}`)
      }

      if (isEssence) {
        if (tile.pet?.level !== 10) {
          selectedTilesActions.remove(
            positionToStringifiedPosition(tile.position),
          )
          selectedTilesActions.set(
            positionToStringifiedPosition(tile.position),
            {
              id: tile.id,
              position: tile.position,
              selectionTypes: new Set(['outline', 'brightness']),
            },
          )
          setEssenceClicked(true)
        }
      }

      if (deleteBiomRef.current && tile.id) {
        selectedTilesActions.remove(
          positionToStringifiedPosition(tile.position),
        )
        selectedTilesActions.set(positionToStringifiedPosition(tile.position), {
          id: tile.id,
          position: tile.position,
          selectionTypes: new Set(['outline', 'brightness']),
        })
      }

      if (placing) {
        setPlacingTileSelected(true)
        selectedTilesActions.remove(
          positionToStringifiedPosition(tile.position),
        )
        selectedTilesActions.set(positionToStringifiedPosition(tile.position), {
          id: tile.id,
          position: tile.position,
          selectionTypes: new Set(['outline', 'brightness']),
        })
      }

      switch (tile.type) {
        case 'empty': {
          if (
            location.search.indexOf('mode=cross') === -1 &&
            !isEssenceApplying &&
            !placing
          ) {
            setIsEssence(false)
            setIsPetSelected(false)
            navigate('/', { replace: true })
            selectedTilesActions.clear()
            const selectedTilePosition =
              pressedTile && stringifiedPositionToPosition(pressedTile)

            // TODO: переделать, работает как MVP
            // Работа с выделением пустой ячейки
            if (
              pressedTile &&
              selectedTilePosition?.x === tile.position.x &&
              selectedTilePosition?.y === tile.position.y
            ) {
              setPressedTile(undefined)
              selectedTilesActions.remove(pressedTile)
            } else {
              setPressedTile(positionToStringifiedPosition(tile.position))
              selectedTilesActions.set(
                positionToStringifiedPosition(tile.position),
                {
                  id: tile.id,
                  position: tile.position,
                  selectionTypes: new Set(['outline', 'brightness']),
                },
              )
              openPopout({ selectedTile: tile.position })
            }
          }
          break
        }
        case 'storage':
          HandleHome()
          if (
            location.search.indexOf('mode=cross') === -1 &&
            !isEssenceApplying &&
            !placing
          ) {
            navigate(`/storage`)
          }
          break
        case 'store':
          HandleHome()
          if (
            location.search.indexOf('mode=cross') === -1 &&
            !isEssenceApplying &&
            !placing
          ) {
            navigate(`/store`)
          }
          break
        case 'stadium':
          HandleHome()
          if (
            location.search.indexOf('mode=cross') === -1 &&
            !isEssenceApplying &&
            !placing
          ) {
            navigate(`/stadium`)
          }
          break
        case 'park':
          HandleHome()
          break
        default:
          return
      }
    },
    [
      navigate,
      openPopout,
      pressedTile,
      selectedTilesActions,
      openCross,
      firstPet,
    ],
  )

  const debouncedHandleTileClick = debounce(handleTileClick, 1000)

  useEffect(() => {
    if (MINI_APP === Platform.VK) {
      bridge.send('VKWebAppHideBannerAd')
      bridge.subscribe(({ detail }) => {
        if (
          detail.type === 'VKWebAppShowBannerAdResult' &&
          detail.data.result
        ) {
          if (window.location.pathname === 'selectPet') {
            bridge.send('VKWebAppHideBannerAd')
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    const unListen = history.listen(({ action }: { action: Action }) => {
      if (action === 'POP') HandleHome()
    })

    return () => {
      unListen()
    }
  }, [])

  // Убираем выделение если закрыт попаут биомов
  useEffect(() => {
    if (!location.search.includes('bioms') && pressedTile) {
      setPressedTile(undefined)
      selectedTilesActions.remove(pressedTile)
    }
  }, [location, pressedTile, selectedTilesActions])

  // Центрируем вьюпорт при изменении размеров окна
  useEffect(() => {
    viewportRef.current?.moveCenter(
      stageDimensions.width / 2,
      stageDimensions.height / 2,
    )
  }, [screenDimensions, stageDimensions.height, stageDimensions.width])

  // Убираем скролл страницы при зуме на vkcom
  useEffect(() => {
    const preventDefault = (e: Event) => {
      e.preventDefault()
    }

    window.addEventListener('mousewheel', preventDefault, { passive: false })

    return () => {
      window.removeEventListener('mousewheel', preventDefault)
    }
  }, [])

  const onBoarding = useOnBoarding()
  const waitLobby = useAppSelector(getWaitLobby)

  // Онбординг
  useEffect(() => {
    const onBoardingCheck = async () => {
      let welcome, cross
      if (MINI_APP === Platform.VK) {
        const { onboarding_welcome, onboarding_cross } = await getVKStorage(
          'onboarding_welcome',
          'onboarding_cross',
        )

        welcome = onboarding_welcome
        cross = onboarding_cross
      }

      if (MINI_APP === Platform.TG) {
        cross = await getItems(['onboarding_cross'])
        welcome = await getItems(['onboarding_welcome'])
        cross = Object.values(cross)[0]
        welcome = Object.values(welcome)[0]
      }
      dispatch(setOnboarding(['cross', cross === 'true']))
      if (welcome === 'true') {
        // Проверка на игру / лобби
        const response = await makeRequest('user.getSelf', {})
        console.info('response from map', response)
        if (!response) return

        if (response.gameData) {
          return navigate('/olympicGame', { state: { ...response.gameData } })
        }

        if (
          response.user.regions.filter(
            ({ pet }) => pet && pet.name.trim() !== '',
          ).length === 0
        ) {
          console.info('[Лобби] Нет петов перед входом в лобби')
          return
        }

        if (/vk_chat_id=[0-9a-zA-Z%]+/.test(getLocationSearch())) {
          console.info('[Лобби] Переход из ВК сервиса в чатах')
          return navigate('/lobby', {
            replace: true,
            state: { showChatConnect: true },
          })
        }

        if (
          LOCATION_HASH.slice(0, 6) === 'lobby_' &&
          LOCATION_HASH.slice(6) !== waitLobby
        ) {
          console.info('[Лобби] Переход по id из хэша')
          dispatch(setAlreadyConnectedOnce(LOCATION_HASH.slice(5)))
          return navigate(`/lobby/${LOCATION_HASH.slice(5)}`, {
            replace: true,
          })
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const tgInWindow = window?.Telegram
        const tgStartParam =
          tgInWindow?.WebApp?.initDataUnsafe?.start_param ?? ''

        console.info(`[Лобби] ${waitLobby} ${tgStartParam}`)

        if (
          tgStartParam.slice(0, 5) === 'lobby' &&
          tgStartParam.slice(5) !== waitLobby
        ) {
          console.info('[Лобби] Переход по стартовым параметрам ТГ')
          dispatch(setAlreadyConnectedOnce(tgStartParam.slice(5)))
          return navigate(`/lobby/${tgStartParam.slice(6)}`, {
            replace: true,
            state: { showChatConnect: true },
          })
        }

        if (response.lobbyData) {
          console.info('[Лобби] Переход по id из базы')
          return navigate(`/lobby/${response.lobbyData.lobbyId}`)
        }

        return
      }

      if (MINI_APP === Platform.VK) {
        await setVKStorage('onboarding_welcome', 'true')
      }
      if (MINI_APP === Platform.TG) {
        await setItem('onboarding_welcome', 'true')
      }
      dispatch(setOnboarding(['welcome', true]))
      await onBoarding('welcome')
    }
    onBoardingCheck()
  }, [dispatch])

  const showOnboardingCross = async () => {
    let cross

    if (MINI_APP === Platform.VK) {
      const { onboarding_cross } = await getVKStorage('onboarding_cross')
      cross = onboarding_cross
    }

    if (MINI_APP === Platform.TG) {
      cross = await getItems(['onboarding_cross'])
      cross = Object.values(cross)[0]
    }

    if (cross === 'true') return

    if (MINI_APP === Platform.VK) {
      await setVKStorage('onboarding_cross', 'true')
    }

    if (MINI_APP === Platform.TG) {
      await setItem('onboarding_cross', 'true')
    }

    dispatch(setOnboarding(['cross', true]))
    await onBoarding('cross', '/?mode=cross', '&mode=cross')
    navigate(-1)
    setTimeout(() => navigate(-1), MODAL_ANIMATION_MS)
  }

  return (
    <Root type="default">
      {location.search !== '?mode=cross' &&
        !isEssence &&
        !deleteBiomRef.current && (
          <Wallet
            mode={'horizontal'}
            customStyle={`position: absolute; background-color: #3070AA30; z-index: 11;
          background: linear-gradient(0deg, rgba(48, 112, 170, 0.19) 0%, rgba(48, 112, 170, 0.19) 100%), linear-gradient(355deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.10) 100%);
          box-shadow: 0px -4px 0px 0px rgba(80, 131, 130, 0.25) inset;`}
          />
        )}

      {!isEssence &&
      location.search.indexOf('mode=cross') === -1 &&
      !placing &&
      !deleteBiomRef.current ? (
        <CrossBlock>
          <Button
            onClick={async () => {
              navigate('/?mode=cross')
              if (onboarding.cross) return
              await showOnboardingCross()
            }}
            disabled={location.search.indexOf('mode=cross') !== -1}
            variant={
              location.search.indexOf('mode=cross') !== -1
                ? 'translucent'
                : !onboarding.cross
                ? 'success'
                : 'primary'
            }
            width="110px"
            style={{ fontSize: 11 }}
          >
            <Cross src={CrossIcon} />
            {t(LOCALES.breed)}
          </Button>
        </CrossBlock>
      ) : (
        ''
      )}
      {isEssence && !isPetSelected && (
        <TextBlock>
          <SuggestTitle mb={'110px'}>
            {t(LOCALES.choosePetUseEssence)}
          </SuggestTitle>
          <Essence>
            <EssenceImage
              src={`${STATIC_IMAGES_PATH}${essence?.essence.photoUrl}`}
            />
            <GenInfo>
              <BlockText width={'100%'}>
                {essence?.essence.experience} {t(LOCALES.exp)}
              </BlockText>
            </GenInfo>
          </Essence>
        </TextBlock>
      )}
      {!!placing && (
        <TextBlock>
          <SuggestTitle mb={'110px'}>
            {t(LOCALES.chooseBiomeToPopulate)}
          </SuggestTitle>
        </TextBlock>
      )}
      {deleteBiom && (
        <TextBlock>
          <SuggestTitle mb={'110px'}>
            {t(LOCALES.chooseBiomeToDelete)}
          </SuggestTitle>
        </TextBlock>
      )}
      {location.search.indexOf('mode=cross') !== -1 && !isPetSelected && (
        <TextBlock>
          <SuggestTitle mb={'95px'}>
            {t(LOCALES.chooseFirstPetToBreed)}
          </SuggestTitle>
        </TextBlock>
      )}
      {location.search.indexOf('mode=cross') !== -1 && isPetSelected && (
        <TextBlock>
          <SuggestTitle mb={'95px'}>
            {t(LOCALES.chooseSecondPetToBreed)}
          </SuggestTitle>
        </TextBlock>
      )}
      <ResizableStage>
        <Viewport
          ref={viewportRef}
          screenWidth={screenDimensions.width}
          screenHeight={screenDimensions.height}
          worldWidth={stageDimensions.width}
          worldHeight={stageDimensions.height}
        >
          <CloudsBackground type="default" />
          <Container sortableChildren x={containerX} y={containerY}>
            <Tiles
              tiles={tiles}
              selectedTiles={selectedTiles}
              onClick={debouncedHandleTileClick}
              selectedTilesActions={selectedTilesActions}
              tileProps={{
                setIsPetSelected: setIsPetSelected,
                setFirstPet: setFirstPet,
                firstPet: firstPet,
                setPetIds: setPetId,
                isPressed: isPetSelected,
                setIsCleared: setIsCleared,
                isCleared: isCleared,
                isEssence: isEssence,
                deleteBiomRef: deleteBiomRef,
                setDeleteBiom: setDeleteBiom,
                deleteBiom: deleteBiom,
                navigate: navigate,
              }}
              placing={!!placing}
              allowedRegion={allowedRegion}
            />
            <SelectedTilesOutline
              tiles={tiles}
              viewport={viewportRef.current}
              selectedTiles={selectedTiles}
              tileDimensions={tileDimensions}
              deleteBiomRef={deleteBiomRef}
            />
          </Container>
        </Viewport>
      </ResizableStage>
      {isPetSelected &&
        firstPet &&
        location.search.indexOf('mode=cross') !== -1 && (
          <SelectedPet>
            <Stage
              width={45}
              height={45}
              options={{
                backgroundAlpha: 0,
                antialias: true,
                resolution: window.devicePixelRatio || 1,
                autoDensity: true,
              }}
            >
              <Entity
                shadow={false}
                entity={
                  firstPet && firstPet.basePet ? (
                    <EntityPet
                      basePet={firstPet.basePet}
                      squeeze={false}
                      x={17}
                      y={45}
                      scale={0.3}
                      skins={firstPet.skins}
                    />
                  ) : (
                    ''
                  )
                }
              />
            </Stage>
            <GenInfo ml={'10px'}>
              <BlockTitle fs={'14px'}>{firstPet.name}</BlockTitle>
              <IconBlock pr={'10px'} mb={'3px'}>
                {firstPet.evolution}
                <Currency mt={'3px'} src={Gen} />
              </IconBlock>
            </GenInfo>
          </SelectedPet>
        )}

      {location.search.indexOf('mode=cross') !== -1 && (
        <HomeContainer>
          <HomeButton
            onClick={() => {
              HandleHome()
              navigate('/', { replace: true })
            }}
          >
            <HomeImage src={HomeIcon} />
          </HomeButton>
        </HomeContainer>
      )}
      {deleteBiom && (
        <HomeContainer>
          {
            <Button
              variant={'dangerous'}
              width="267px"
              onClick={handleDeleteBiom}
            >
              {t(LOCALES.delete)}
            </Button>
          }
          <HomeButton
            onClick={() => {
              HandleHome()
              navigate('/', { replace: true })
            }}
          >
            <HomeImage src={HomeIcon} />
          </HomeButton>
        </HomeContainer>
      )}
      {isEssence ? (
        <HomeContainer>
          {essenceClicked && (
            <Button
              variant={applyLoad ? 'translucent' : 'success'}
              width="267px"
              onClick={handleUseEssence}
              disabled={applyLoad}
            >
              <PolygonBlock>
                <PolygonPaw src={PolygonIcon} />
                <Apply src={ApplyIcon} />
              </PolygonBlock>
              {t(LOCALES.apply)}
            </Button>
          )}
          <HomeButton
            onClick={() => {
              HandleHome()
              navigate('/', { replace: true })
            }}
          >
            <HomeImage src={HomeIcon} />
          </HomeButton>
        </HomeContainer>
      ) : (
        ''
      )}
      {placing ? (
        <HomeContainer>
          {placingTileSelected && (
            <Button
              variant={applyLoad ? 'translucent' : 'success'}
              width="267px"
              onClick={placeOnTile}
              disabled={applyLoad}
            >
              <PolygonBlock>
                <EggIcon src={EggIconImage} />
                <Apply src={ApplyIcon} />
              </PolygonBlock>
              {t(LOCALES.settle)}
            </Button>
          )}
          <HomeButton
            onClick={() => {
              HandleHome()
              navigate('/', { replace: true })
            }}
          >
            <HomeImage src={HomeIcon} />
          </HomeButton>
        </HomeContainer>
      ) : (
        ''
      )}
    </Root>
  )
}
