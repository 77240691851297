import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import bridge from '@vkontakte/vk-bridge'
import { Spacing, Spinner } from '@vkontakte/vkui'
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app'
import { io, Socket } from 'socket.io-client'
import { MINI_APP } from 'src'
import {
  API_URL,
  APP_LANG,
  STATIC_IMAGES_PATH,
  VK_MINI_APP_LINK,
} from 'src/config/constants'
import { usePopout } from 'src/hooks'
import { useSystemBackListener } from 'src/hooks/useSystemBackListener'
import { makeRequest } from 'utils/api'
import copyTextToClipboard from 'utils/copyTextToClipboard'
import { getLocationSearch, getVkUserId } from 'utils/getLocationSearch'

import cancelIcon from 'assets/icons/cancelIcon.svg'
import changePetPlaceholderIcon from 'assets/svg/lobby/changePetIcon.svg'
import lobbyUsersIcon from 'assets/svg/lobby/lobby_users.svg'
import { BackButton, Wallet } from 'components/base'
import { Page, PageContainer, PageHeader } from 'components/page'
import { Timer } from 'components/Timer'
import { HeaderText } from 'components/typography'
import { useAppDispatch, useAppSelector } from 'store/index'
import { getSelectedPet, setSelectedPet } from 'store/selectPet'

import { LOCALES } from '@gatto/locales'
import {
  GameType,
  GameTypeText,
  Platform,
  SocketResponse,
  WaitLobbyLeaveEvent,
  WaitLobbyLeaveReasons,
  WaitLobbyNewGame,
  WaitLobbySocketEvents,
  WaitLobbyState,
  WaitLobbyUserInfo,
  WaitLobbyUserStatus,
} from '@gatto/shared'

import { Avatar } from './Avatar'
import { PlayerInfo } from './PlayerInfo'
import {
  ChangePetButton,
  ControlButtons,
  FriendsList,
  FriendsListEmpty,
  GameItem,
  GamesList,
  InfoBlock,
  KickButton,
  LobbyUsersIcon,
  ShareButton,
  StartButton,
  Title,
  Wrapper,
} from './styles'

interface LobbyPageLocationState {
  petId: string
  kickUser?: string
  leave?: boolean
  showChatConnect?: boolean
}

export const LobbyPage: FC = () => {
  const lobbyId = useParams()?.lobbyId
  const location = useLocation()
  const selectedPet = useAppSelector(getSelectedPet)
  const dispatch = useAppDispatch()
  const { getItem } = useCloudStorage()
  const [locationState, setLocationState] =
    useState<LobbyPageLocationState | null>(null)
  const navigate = useNavigate()
  const [socketClient, setSocketClient] = useState<Socket | null>(null)
  const [lobbyState, setLobbyState] = useState<WaitLobbyState | null>(null)
  const [users, setUsers] = useState<WaitLobbyUserInfo[]>([])
  const [owner, setOwner] = useState<WaitLobbyUserInfo | null>(null)
  const [isMinUsersReady, setIsMinUsersReady] = useState(true)
  const [isShowedChatConnect, setIsShowedChatConnect] = useState(false)
  const [isDiedLobby, setIsDiedLobby] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const { t } = useTranslation()

  useSystemBackListener(({ location }) => {
    if (!loaded) return
    if (!isDiedLobby) {
      if (lobbyState) {
        if (socketClient?.connected) {
          popoutExit({
            lobbyId: lobbyState.id,
          })
        } else {
          navigate('/', {
            replace: true,
          })
        }
      } else {
        navigate(`${location.pathname}${location.search}`, {
          state: location.state,
          replace: true,
        })
      }
    } else {
      navigate('/', { replace: true })
    }
  })

  const popoutExit = usePopout('lobbyLeave', true, {})

  const popoutKick = usePopout('lobbyKick', true, {})

  const popoutNoPetWasSelected = usePopout('statusAlert', false, {
    type: 'info',
    header: t(LOCALES.lobbySelectTimeout),
  })

  const popoutKicked = usePopout('statusAlert', true, {
    type: 'info',
    header: t(LOCALES.error_kicked),
    navigatePath: '/',
  })

  const popoutLiveTimeOut = usePopout('statusAlert', true, {
    type: 'info',
    header: t(LOCALES.lobbyLiveTimeoit),
    navigatePath: '/',
  })

  const popoutOwnerLeave = usePopout('statusAlert', true, {
    type: 'error',
    header: t(LOCALES.lobbyOwnerLeave),
    navigatePath: '/',
  })

  const popout = usePopout('statusAlert', true, {
    type: 'error',
    header: t(LOCALES.unknownError),
    navigatePath: '/',
  })

  useEffect(() => {
    setLocationState(location.state)
    if (location.state && !isDiedLobby) {
      if (socketClient && socketClient.connected) {
        if (location.state.leave) {
          socketClient.emit(WaitLobbySocketEvents.Leave, {
            userId: Number(getVkUserId()),
          })
          setIsDiedLobby(true)
          navigate('/', { replace: true })
        }
        if (location.state.kickUser) {
          socketClient.emit(WaitLobbySocketEvents.Leave, {
            userId: location.state.kickUser,
          })
        }
      }
    }
  }, [socketClient, location])

  useEffect(() => {
    if (!lobbyState) return
    let list = Object.values(lobbyState.users)
    if (Number(getVkUserId()) === lobbyState.ownerId)
      list = list.filter(({ id }) => id !== Number(getVkUserId()))
    setUsers(list)
  }, [lobbyState])

  function getLobby() {
    let token = getLocationSearch()
    if (MINI_APP === Platform.TG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      token = window.Telegram.WebApp.initData
    }
    setLocationState(location.state)
    makeRequest(
      lobbyId ? 'waitLobby.getConnectionUrl' : 'waitLobby.getFreest',
      lobbyId ? { lobbyId } : {},
    )
      .then(({ connectionUrl }) => {
        if (connectionUrl !== '') {
          const client = io(connectionUrl, {
            transports: ['websocket'],
            auth: {
              token: `Bearer ${
                MINI_APP === Platform.TG ? token : getLocationSearch()
              }`,
            },
          })

          client.on('connect_error', (error) => {
            setIsDiedLobby(true)
            popout({
              type: 'error',
              navigatePath: '/',
              header: t(error.message ?? LOCALES.unknownError),
            })
          })

          setSocketClient(client)
        }
      })
      .catch(({ response: { status } }) => {
        console.info('Error', status)
        setIsDiedLobby(true)
        popout({
          type: 'error',
          navigatePath: '/',
          header: t(
            status === 404 ? LOCALES.error_lobbyNotFound : LOCALES.unknownError,
          ),
        })
      })
  }

  useEffect(() => {
    if (isDiedLobby) {
      navigate('/', { replace: true })
    } else {
      setLoaded(true)
      if (MINI_APP === Platform.VK) {
        bridge
          .send('VKWebAppStorageGet', { keys: ['selectedPet'] })
          .then((data) => {
            dispatch(setSelectedPet(data.keys[0]?.value))
            getLobby()
          })
      }

      if (MINI_APP === Platform.TG) {
        getItem('selectedPet').then((data) => {
          dispatch(setSelectedPet(data))
          getLobby()
        })
      }
    }
  }, [])

  useEffect(() => {
    if (!socketClient) return

    socketClient.emit(
      WaitLobbySocketEvents.Connect,
      {
        lobbyId: lobbyId ?? null,
        petId: locationState?.petId ?? selectedPet ?? null,
      },
      (
        response: SocketResponse<{
          message: string
        }>,
      ) => {
        if (!response?.success) {
          setIsDiedLobby(true)
          if (response.data?.message === LOCALES.error_userAlreadyInGame) {
            makeRequest('user.getSelf', {}).then(({ gameData }) => {
              navigate('/olympicGame', { state: { ...gameData } })
            })
          } else {
            popout({
              type: 'error',
              navigatePath: '/',
              header: t(response.data?.message ?? LOCALES.unknownError),
            })
          }
          socketClient.disconnect()
        }
      },
    )

    socketClient.on(
      WaitLobbySocketEvents.Info,
      async (response: WaitLobbyState) => {
        console.info(response.id)
        if (locationState?.leave) {
          socketClient.emit(WaitLobbySocketEvents.Leave, {
            userId: Number(getVkUserId()),
          })
          navigate('/')
        } else {
          setLobbyState(response)
          setIsMinUsersReady(
            Object.values(response.users).filter(
              (user) => user.status === WaitLobbyUserStatus.Ready,
            ).length > 1,
          )

          setOwner(
            Object.values(response.users).find(
              (user) => user.id === response.ownerId,
            ) ?? null,
          )

          if (locationState?.showChatConnect && !isShowedChatConnect) {
            setIsShowedChatConnect(true)
            // Такого метода в списке нет, но в шипе то же самое, так что...
            // https://dev.vk.com/ru/bridge/VKWebAppAddToChat
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            bridge.send('VKWebAppAddToChat', {
              action_title: LOCALES.lobbyTrainPets,
              close_app: true,
              hash: 'lobby' + response.id,
            })
          }
        }
      },
    )

    socketClient.on(
      WaitLobbySocketEvents.NewGame,
      async (response: WaitLobbyNewGame) => {
        const anchorPetId =
          locationState?.petId ??
          selectedPet ??
          lobbyState?.users[Number(getVkUserId())]?.pet?._id
        if (
          !anchorPetId ||
          !response.pets.find((u) => u.owner === +getVkUserId())
        )
          return popoutNoPetWasSelected()
        navigate('/olympicGame', {
          state: {
            anchorPetId: anchorPetId,
            data: {
              game: response.game,
              pets: response.pets,
            },
            lobbyId: response.lobbyId,
          },
          replace: true,
        })
      },
    )

    socketClient.on(
      WaitLobbySocketEvents.Leave,
      async (response: WaitLobbyLeaveEvent) => {
        console.info(`Disconnected: ${response.reason}`)
        setIsDiedLobby(true)
        switch (response.reason) {
          case WaitLobbyLeaveReasons.LiveTimeOut:
            popoutLiveTimeOut()
            break
          case WaitLobbyLeaveReasons.OwnerLeave:
            if (response.ownerId !== Number(getVkUserId())) popoutOwnerLeave()
            break
          case WaitLobbyLeaveReasons.NoPetWasSelected:
            popoutNoPetWasSelected()
            break
          case WaitLobbyLeaveReasons.Kicked:
            popoutKicked()
            break
          default:
            popout()
            break
        }
      },
    )

    socketClient.on('disconnect', async () => {
      console.info(`Forcibly disconnected`)

      setIsDiedLobby(true)

      // Проверка на игру
      const response = await makeRequest('user.getSelf', {})
      if (response.gameData) {
        return navigate('/olympicGame', { state: { ...response.gameData } })
      }
    })

    return () => {
      if (socketClient) {
        setIsDiedLobby(true)
        socketClient.disconnect()
      }
    }
  }, [socketClient])

  const leave = (userId: number) => {
    if (socketClient && lobbyState) {
      if (userId === Number(getVkUserId())) {
        popoutExit({
          lobbyId: lobbyState.id,
        })
      } else {
        popoutKick({
          user: lobbyState.users[userId],
          lobbyId: lobbyState.id,
        })
      }
    }
  }

  const setGameType = (gameType: GameType) => {
    if (
      socketClient &&
      lobbyState &&
      lobbyState.ownerId === Number(getVkUserId()) &&
      lobbyState.gameType !== gameType
    ) {
      socketClient.emit(WaitLobbySocketEvents.SetGameType, {
        gameType,
      })
    }
  }

  const startGame = () => {
    if (
      socketClient &&
      lobbyState &&
      lobbyState.gameStartedAt === -1 &&
      isMinUsersReady
    ) {
      socketClient.emit(WaitLobbySocketEvents.Start)
    }
  }

  const shareLobby = async () => {
    if (lobbyState) {
      if (MINI_APP === Platform.VK) {
        bridge.send('VKWebAppShare', {
          link: `${VK_MINI_APP_LINK}#lobby${lobbyState.id}`,
        })
      }

      if (MINI_APP === Platform.TG) {
        setIsCopy(true)
        try {
          copyTextToClipboard(
            `https://t.me/gatto_gamebot/gatto?startapp=lobby_${lobbyState.id}`,
          )
        } catch (error) {
          console.info('Ошибка при копировании ссылки:')
        }

        setTimeout(() => {
          setIsCopy(false)
        }, 1000)
      }
    }
  }

  const changePetIcon = <img src={changePetPlaceholderIcon} />
  const user = lobbyState?.users[Number(getVkUserId())]
  const isGameStarting =
    typeof lobbyState?.gameStartedAt === 'number' &&
    lobbyState?.gameStartedAt !== -1

  return (
    <PageContainer
      style={{
        overflow: 'auto',
      }}
    >
      {lobbyState && user && owner ? (
        <>
          <PageHeader
            over={<Wallet mode="horizontal" />}
            before={
              <BackButton
                onClick={() => !isGameStarting && leave(Number(getVkUserId()))}
              />
            }
          >
            <HeaderText textAlign="left" padding="0 5px">
              {t(LOCALES.lobby)}
            </HeaderText>
          </PageHeader>
          <Wrapper>
            <Title weight={600}>{t(LOCALES.lobbySelectGame)}</Title>
            <GamesList>
              <GameItem
                pos="0 98%"
                cursor={user.id === owner.id}
                selected={lobbyState.gameType === GameType.Swim}
              >
                <img
                  src={
                    APP_LANG === 'ru'
                      ? `${STATIC_IMAGES_PATH}/uploads/stadium/swim.png`
                      : `${STATIC_IMAGES_PATH}/uploads/stadium/swim_eng.png`
                  }
                  onClick={() => setGameType(GameType.Swim)}
                />
              </GameItem>
              <GameItem
                pos="0 23%"
                cursor={user.id === owner.id}
                selected={lobbyState.gameType === GameType.Race}
              >
                <img
                  src={
                    APP_LANG === 'ru'
                      ? `${STATIC_IMAGES_PATH}/uploads/stadium/run.png`
                      : `${STATIC_IMAGES_PATH}/uploads/stadium/run_eng.png`
                  }
                  onClick={() => setGameType(GameType.Race)}
                />
              </GameItem>
            </GamesList>
          </Wrapper>
          <Wrapper>
            <InfoBlock>
              {owner.id !== user.id || owner.pet ? (
                <>
                  <Avatar pet={owner.pet} photoUrl={owner.about.photoUrl} />
                  <PlayerInfo user={owner} ownerId={owner.id} width="auto" />
                </>
              ) : (
                ''
              )}

              {user.id === owner.id ? (
                <ChangePetButton
                  variant="primary"
                  width={user.pet ? 'auto' : '100%'}
                  // iconComponent={changePetIcon}
                  // fontSize={user.pet ? 10 : 16}
                  // buttonGap={'m'}
                  // padding={
                  //   user.pet ? '10px 6px 13px 6px' : '14px 11px 17px 11px'
                  // }
                  style={{
                    padding: user.pet
                      ? '10px 6px 13px 6px'
                      : '14px 11px 17px 11px',
                    fontSize: user.pet ? 10 : 16,
                  }}
                  disabled={isGameStarting}
                  onClick={() => {
                    if (lobbyState.gameStartedAt === -1)
                      navigate('/selectPet', {
                        state: {
                          headerText: GameTypeText[lobbyState.gameType],
                          gameType: lobbyState.gameType,
                          redirectTo: `/lobby/${lobbyState.id}`,
                          redirectReplace: true,
                        },
                      })
                  }}
                >
                  <img src={changePetPlaceholderIcon} />
                  {t(
                    user.pet ? LOCALES.lobbyChangePet : LOCALES.lobbyChoosePet,
                  )}
                </ChangePetButton>
              ) : (
                ''
              )}
            </InfoBlock>
          </Wrapper>
          <Wrapper>
            <Title weight={700}>{t(LOCALES.playersList)}</Title>
            <FriendsList>
              {users.length ? (
                users
                  .filter(({ id }) => id !== owner.id)
                  .map((u, index) => (
                    <InfoBlock key={index}>
                      <Avatar pet={u.pet} photoUrl={u.about.photoUrl} />
                      <PlayerInfo
                        width="auto"
                        user={u}
                        ownerId={lobbyState.ownerId}
                      />

                      {user.id === u.id ? (
                        <ChangePetButton
                          variant="primary"
                          width={'auto'}
                          // iconComponent={changePetIcon}
                          // fontSize={10}
                          // buttonGap={'m'}
                          // padding={
                          //   user.pet
                          //     ? '10px 6px 13px 6px'
                          //     : '14px 11px 17px 11px'
                          // }
                          style={{
                            padding: user.pet
                              ? '10px 6px 13px 6px'
                              : '14px 11px 17px 11px',
                            fontSize: 10,
                          }}
                          disabled={isGameStarting}
                          onClick={() => {
                            if (lobbyState.gameStartedAt === -1)
                              navigate('/selectPet', {
                                state: {
                                  headerText: GameTypeText[lobbyState.gameType],
                                  gameType: lobbyState.gameType,
                                  redirectTo: `/lobby/${lobbyState.id}`,
                                  redirectReplace: true,
                                },
                              })
                          }}
                        >
                          <img src={changePetPlaceholderIcon} />
                          {t(
                            user.pet
                              ? LOCALES.lobbyChangePet
                              : LOCALES.lobbyChoosePet,
                          )}
                        </ChangePetButton>
                      ) : (
                        ''
                      )}

                      {Number(getVkUserId()) === lobbyState.ownerId ? (
                        <KickButton
                          hidden={isGameStarting}
                          onClick={() => leave(u.id)}
                          src={cancelIcon}
                          color="#ffffff"
                        />
                      ) : (
                        <></>
                      )}
                    </InfoBlock>
                  ))
              ) : (
                <FriendsListEmpty>
                  <LobbyUsersIcon src={lobbyUsersIcon} />
                  {t(LOCALES.lobbyIsEmpty)}
                </FriendsListEmpty>
              )}
            </FriendsList>
          </Wrapper>
          <Wrapper style={{ marginTop: 'auto' }}>
            <Timer
              start={lobbyState.liveTimeStart}
              end={
                lobbyState.gameStartedAt === -1
                  ? lobbyState.liveTimeEnd
                  : lobbyState.gameStartedAt
              }
              reverse={true}
              color={isGameStarting ? 'green' : undefined}
            />
          </Wrapper>
          <Spacing size={92} />
          <ControlButtons>
            <ShareButton
              // fontSize={20}
              // height="auto"
              width="100%"
              // padding="14px 14px 17px 14px"
              style={{
                padding: '14px 14px 17px 14px',
                fontSize: 20,
              }}
              variant="translucent"
              onClick={shareLobby}
              disabled={isGameStarting || isCopy}
            >
              {/*{MINI_APP === Platform.VK*/}
              {/*  ? 'Пригласить'*/}
              {/*  : isCopy*/}
              {/*  ? 'Скопировано'*/}
              {/*  : 'Дай ссылку!'}*/}
              {t(LOCALES.lobbyShareVK)}
            </ShareButton>
            {Number(getVkUserId()) === lobbyState.ownerId ? (
              <StartButton
                // fontSize={20}
                // height="auto"
                width="50%"
                // padding="14px 14px 17px 14px"
                style={{
                  padding: '14px 14px 17px 14px',
                  fontSize: 20,
                }}
                variant="success"
                onClick={startGame}
                disabled={!isMinUsersReady || isGameStarting}
              >
                {t(LOCALES.choosePet_startRoom)}
              </StartButton>
            ) : (
              <></>
            )}
          </ControlButtons>
        </>
      ) : (
        <Spinner />
      )}
    </PageContainer>
  )
}
